import axios from "axios";
import { Domain } from "Domain";
import React, { useEffect, useState } from "react";
import Table from "components/Table/Table1.js";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button.js";
import { Container } from "@material-ui/core";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@material-ui/core";
import LoadingOverlay from "react-loading-overlay";

// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { Domain } from "Domain";
// import { makeStyles } from "@material-ui/core/styles";
// import ConfirmationNumberSharpIcon from "@material-ui/icons/ConfirmationNumberSharp";
// import Update from "@material-ui/icons/Update";
// import GridItem from "components/Grid/GridItem.js";
// import GridContainer from "components/Grid/GridContainer.js";
// import Card from "components/Card/Card.js";
// import CardHeader from "components/Card/CardHeader.js";
// import CardIcon from "components/Card/CardIcon.js";
// import CardFooter from "components/Card/CardFooter.js";
// import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

// const useStyles = makeStyles(styles);

export default function RecentUsers() {
  const [startDate, setStartDate] = useState("");

  // const classes = useStyles();
  // const [Raised, setRaised] = useState(0);
  // const [Assigned, setAssigned] = useState(0);
  // const [Working, setWorking] = useState(0);
  // const [Completed, setCompleted] = useState(0);
  // const [Pending, setPending] = useState(0);
  //To get Raised Tickets list from database
  // useEffect(() => {
  //   let formData = new FormData();

  //   formData.append("Status", "Raised");
  //   axios({
  //     method: "post",
  //     url: Domain + "/GetTicketsByStatus.php",
  //     data: formData,
  //     config: { headers: { "Content-Type": "multipart/form-data" } },
  //   })
  //     .then(function (response) {
  //       //handle success
  //       if (response.data.success) {
  //         setRaised(response.data.TotalCount);
  //       }
  //     })
  //     .catch(function (response) {
  //       //handle error
  //       console.log(response);
  //     });
  // }, []);

  // //To get Assigned Tickets list from database
  // useEffect(() => {
  //   let formData = new FormData();

  //   formData.append("Status", "Assigned");
  //   axios({
  //     method: "post",
  //     url: Domain + "/GetTicketsByStatus.php",
  //     data: formData,
  //     config: { headers: { "Content-Type": "multipart/form-data" } },
  //   })
  //     .then(function (response) {
  //       //handle success
  //       if (response.data.success) {
  //         setAssigned(response.data.TotalCount);
  //       }
  //     })
  //     .catch(function (response) {
  //       //handle error
  //       console.log(response);
  //     });
  // }, []);

  // //To get Working Tickets list from database
  // useEffect(() => {
  //   let formData = new FormData();

  //   formData.append("Status", "Working");
  //   axios({
  //     method: "post",
  //     url: Domain + "/GetTicketsByStatus.php",
  //     data: formData,
  //     config: { headers: { "Content-Type": "multipart/form-data" } },
  //   })
  //     .then(function (response) {
  //       //handle success
  //       if (response.data.success) {
  //         setWorking(response.data.TotalCount);
  //       }
  //     })
  //     .catch(function (response) {
  //       //handle error
  //       console.log(response);
  //     });
  // }, []);

  // //To get Completed Tickets list from database
  // useEffect(() => {
  //   let formData = new FormData();

  //   formData.append("Status", "Completed");
  //   axios({
  //     method: "post",
  //     url: Domain + "/GetTicketsByStatus.php",
  //     data: formData,
  //     config: { headers: { "Content-Type": "multipart/form-data" } },
  //   })
  //     .then(function (response) {
  //       //handle success
  //       if (response.data.success) {
  //         setCompleted(response.data.TotalCount);
  //       }
  //     })
  //     .catch(function (response) {
  //       //handle error
  //       console.log(response);
  //     });
  // }, []);
  // //To get Pending Tickets list from database
  // useEffect(() => {
  //   let formData = new FormData();

  //   formData.append("Status", "Pending");
  //   axios({
  //     method: "post",
  //     url: Domain + "/GetTicketsByStatus.php",
  //     data: formData,
  //     config: { headers: { "Content-Type": "multipart/form-data" } },
  //   })
  //     .then(function (response) {
  //       //handle success
  //       if (response.data.success) {
  //         setPending(response.data.TotalCount);
  //       }
  //     })
  //     .catch(function (response) {
  //       //handle error
  //       console.log(response);
  //     });
  // }, []);
  const [notifications, setNotifications] = useState([]);
  const [edit, setEdit] = React.useState([]);
  const [refresh, setRefresh] = useState(false);
  const Notifications = notifications.map((d) => ({
    Id: d.id,
    Name: d.FullName,
    Mobile: d.Mobile,
    District: d.District,
    UpiId: d.UpiId,
    Status: d.Status,
  }));

  console.log("edit::", edit);
  useEffect(() => {
    let formData = new FormData();
    formData.append("Id", 0);
    axios({
      method: "post",
      url: Domain + "/Admin/GetAllRecentUsers.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("Notifications:", response);
        if (response.data.Success) {
          setNotifications(response.data.Data);
          //Get PMF lock details
        } else {
          console.log("failed");
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
        console.log("error");
      });
  }, [refresh]);
  useEffect(() => {
    if (edit != "") {
      let data = {
        Id: edit,
      };
      axios({
        method: "post",
        url: Domain + "/Admin/ConfrimNotification.php",
        data: JSON.stringify(data),
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then(function (response) {
          //handle success
          console.log("Confirmed:", response);
          if (response.data.Success) {
            if (refresh) setRefresh(false);
            else setRefresh(true);
            //Get PMF lock details
          } else {
            console.log("failed");
          }
        })
        .catch(function (response) {
          //handle error
          console.log(response);
          console.log("error");
        });
    }
  }, [edit]);
  const [data, setData] = React.useState({ Id: "" });
  function HandleData(e) {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
    console.log(newData);
  }
  const [loading, setloading] = useState(false);
  function HandleSearch() {
    setloading(true);
    console.log("startDate::", startDate);
    let formdata = new FormData();
    formdata.append("Id", data.Id);
    formdata.append("Date", startDate);
    axios({
      method: "post",
      url: Domain + "/Admin/GetRecentUserByDate.php",
      data: formdata,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("PMF details by id:", response);
        if (response.data.Success) {
          setNotifications(response.data.Data);
          setloading(false);
        } else {
          console.log("failed");
          setNotifications([]);
          setloading(false);
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
        console.log("error");
      });
  }
  return (
    <>
      <br />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info">Recent Users</CardHeader>
            <br />
            <LoadingOverlay active={loading} spinner text="Please Wait..">
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  Search By Id
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    onChange={(e) => HandleData(e)}
                    value={data.Id}
                    labelText="Enter Id"
                    id="Id"
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Select Date"
                      value={startDate}
                      onChange={(newValue) => {
                        setStartDate(newValue.toLocaleDateString("en-CA"));
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                  <br />
                  Count : <b>{notifications.length}</b>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <Button color="info" onClick={HandleSearch}>
                    Search
                  </Button>
                </GridItem>
              </GridContainer>
            </LoadingOverlay>
            <Container>
              <Table
                tableHeaderColor="info"
                tableHead={[
                  "Consumer ID",
                  "Name",
                  "Mobile",
                  "District",
                  "UpiId",
                  "Status",
                ]}
                tableData={Notifications}
                setEdit={setEdit}
                loading={false}
                noAction={true}
              />
            </Container>
          </Card>
        </GridItem>
      </GridContainer>
    </>
    // <div>
    //   <GridContainer>
    //     <GridItem xs={12} sm={6} md={3}>
    //       <Card>
    //         <CardHeader color="warning" stats icon>
    //           <CardIcon color="warning">
    //             <ConfirmationNumberSharpIcon />
    //           </CardIcon>
    //           <p className={classes.cardCategory}>
    //             <b>Recently Raised Tickets</b>
    //           </p>
    //           <h3 className={classes.cardTitle}>
    //             <b>{Raised}</b>
    //           </h3>
    //         </CardHeader>
    //         <CardFooter stats>
    //           <div className={classes.stats}>
    //             <Update />
    //             Just Updated
    //           </div>
    //         </CardFooter>
    //       </Card>
    //     </GridItem>
    //     <GridItem xs={12} sm={6} md={3}>
    //       <Card>
    //         <CardHeader color="primary" stats icon>
    //           <CardIcon color="primary">
    //             <ConfirmationNumberSharpIcon />
    //           </CardIcon>
    //           <p className={classes.cardCategory}>
    //             <b>Assigned Tickets Count</b>
    //           </p>
    //           <h3 className={classes.cardTitle}>
    //             <b>{Assigned}</b>
    //           </h3>
    //         </CardHeader>
    //         <CardFooter stats>
    //           <div className={classes.stats}>
    //             <Update />
    //             Just Updated
    //           </div>
    //         </CardFooter>
    //       </Card>
    //     </GridItem>
    //     <GridItem xs={12} sm={6} md={3}>
    //       <Card>
    //         <CardHeader color="info" stats icon>
    //           <CardIcon color="info">
    //             <ConfirmationNumberSharpIcon />
    //           </CardIcon>
    //           <p className={classes.cardCategory}>
    //             <b>Working Tickets Count.. </b>
    //           </p>
    //           <h3 className={classes.cardTitle}>
    //             <b>{Working}</b>
    //           </h3>
    //         </CardHeader>
    //         <CardFooter stats>
    //           <div className={classes.stats}>
    //             <Update />
    //             Just Updated
    //           </div>
    //         </CardFooter>
    //       </Card>
    //     </GridItem>
    //     <GridItem xs={12} sm={6} md={3}>
    //       <Card>
    //         <CardHeader color="success" stats icon>
    //           <CardIcon color="success">
    //             <ConfirmationNumberSharpIcon />
    //           </CardIcon>
    //           <p className={classes.cardCategory}>
    //             <b>Completed Ticket Count</b>
    //           </p>
    //           <h3 className={classes.cardTitle}>
    //             <b>{Completed}</b>
    //           </h3>
    //         </CardHeader>
    //         <CardFooter stats>
    //           <div className={classes.stats}>
    //             <Update />
    //             Just Updated
    //           </div>
    //         </CardFooter>
    //       </Card>
    //     </GridItem>
    //     <GridItem xs={12} sm={6} md={3}>
    //       <Card>
    //         <CardHeader color="danger" stats icon>
    //           <CardIcon color="danger">
    //             <ConfirmationNumberSharpIcon />
    //           </CardIcon>
    //           <p className={classes.cardCategory}>
    //             <b>Pending Ticket Count</b>
    //           </p>
    //           <h3 className={classes.cardTitle}>
    //             <b>{Pending}</b>
    //           </h3>
    //         </CardHeader>
    //         <CardFooter stats>
    //           <div className={classes.stats}>
    //             <Update />
    //             Just Updated
    //           </div>
    //         </CardFooter>
    //       </Card>
    //     </GridItem>
    //   </GridContainer>
    // </div>
  );
}
