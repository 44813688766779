import React from "react";
import { Domain } from "Domain";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
// import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import CardFooter from "components/Card/CardFooter.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import Danger from "components/Typography/Danger";
import LoadingOverlay from "react-loading-overlay";
// import SingleSelect from "components/SingleSelect";
// import Pagination from "components/Pagination/Pagination";
import axios from "axios";
import { useState } from "react";
import Modal from "react-modal";

import { Close } from "@material-ui/icons";
import { Dialog, Slide, AppBar, IconButton, Toolbar } from "@material-ui/core";
import { useEffect } from "react";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
// import { data } from "./data.json";
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export default function Subjects() {
  const [errorMessage, setErrorMessage] = useState("Error try again!");
  const classes = useStyles();
  const [saved, setSaved] = React.useState(false);
  const [deleted, setDeleted] = React.useState(false);
  // const [deletee, setDelete] = React.useState([]);
  // const [events, setEvents] = React.useState([]);
  const [validated, setValidated] = React.useState(true);
  // const [loading, setLoading] = React.useState(true);
  const [deleting, setDeleting] = React.useState(false);
  const [empty, setEmpty] = React.useState(false);
  const [userData, setUserData] = useState([]);

  const [givehelpusers, setGivehelp] = useState([]);
  const [givehelpusers_club2, setGivehelpClub2] = useState([]);
  useEffect(() => {
    let formData = new FormData();
    formData.append("Id", userData.id);
    formData.append("Club", "Club2");

    axios({
      method: "post",
      url: Domain + "/Admin/GetGBox.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("givehelp users Club2:::", response);
        if (response.data.Success) {
          setGivehelpClub2(response.data.Data);
        } else {
          console.log("failed");
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
        console.log("error");
      });
  }, [userData.id]);
  useEffect(() => {
    let formData = new FormData();
    formData.append("Id", userData.id);
    formData.append("Amount", 200);
    formData.append("Club", "Club1");

    axios({
      method: "post",
      url: Domain + "/Admin/GetGBox.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("givehelp users:::", response);
        if (response.data.Success) {
          setGivehelp(response.data.Data);
        } else {
          console.log("failed");
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
        console.log("error");
      });
  }, [userData.id]);
  // const [Courses, setCourses] = React.useState([]);
  // const [semesters, setSemesters] = React.useState([]);
  // const [saving, setSaving] = React.useState(false);
  // const [TotalCount, setTotalCount] = React.useState();

  //Converting json response to passdata for react select

  // const Subjectdata = events.map((d) => ({
  //   Id: d.Id,
  //   CourseName: d.CourseName,
  //   SemesterNo: d.SemesterNo,
  //   SubjectName: d.SubjectName,
  //   SubjectCode: d.SubjectCode,
  //   Description: d.Description,
  // }));
  // const [TotalCount, setTotalCount] = React.useState();
  // const [pageIndex, setPageIndex] = useState(0);
  // const [pagination, setPagination] = useState(false);

  // useEffect(() => {
  //   let passData = {
  //     PageIndex: pageIndex,
  //     PageSize: 10,
  //   };
  //   fetch(Domain + "/api/Admin/GetAllSubjects/", {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(passData),
  //   })
  //     .then((response) => response.json())

  //     .then((json) => {
  //       setEvents(json.Data);
  //       if (json.TotalCount > 10) {
  //         console.log("pages", Math.ceil(json.TotalCount / 10));
  //         setTotalCount(Math.ceil(json.TotalCount / 10));

  //         setPagination(true);
  //       }
  //     });
  // }, [pageIndex]);
  //Saved Notification trigger
  const showSavedNotification = () => {
    if (!saved) {
      setSaved(true);
      setTimeout(function () {
        setSaved(false);
      }, 3000);
    }
  };
  const [transfered, setTransfered] = useState(false);

  const showTrasferedNotification = () => {
    if (!transfered) {
      setTransfered(true);
      setTimeout(function () {
        setTransfered(false);
      }, 3000);
    }
  };
  const [pwdReset, setPWDReset] = useState(false);
  const showPwdResetNotification = () => {
    if (!pwdReset) {
      setPWDReset(true);
      setTimeout(function () {
        setPWDReset(false);
      }, 3000);
    }
  };
  //Deleted Notification Trigger
  const showDeletedNotification = () => {
    if (!deleted) {
      setDeleted(true);
      setTimeout(function () {
        setDeleted(false);
      }, 3000);
    }
  };
  //Form Data
  const [data, setData] = React.useState({
    Id: "",
    Email: "",
    Mobile: "",
  });

  //Function to handle Data input
  function HandleData(e) {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
    console.log(newData);
  }
  function HandleTransferData(e) {
    const newData = { ...transferdata };
    newData[e.target.id] = e.target.value;
    setTransferdata(newData);
    console.log(newData);
  }
  function HandleReactivateData(e) {
    const newData = { ...referalForReactivate };
    newData[e.target.id] = e.target.value;
    setReferalForReactivate(newData);
    console.log(newData);
  }
  function HandleClear() {
    setValidated(true);
    setData({
      Id: "",
      Email: "",
      Mobile: "",
    });
  }
  //Function for Validating fields
  function ValidateFields() {
    if (data.Id != "" || data.Email != "" || data.Mobile != "") {
      return true;
    } else return false;
  }

  //Function to save Data
  function HandleSave() {
    if (ValidateFields()) {
      setDeleting(true);
      setValidated(true);
      let formData = new FormData();
      formData.append("Id", data.Id);
      formData.append("Mobile", data.Mobile);
      formData.append("Email", data.Email);
      axios({
        method: "post",
        url: Domain + "/Admin/SearchUser.php",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then(function (response) {
          //handle success
          console.log("Get User by id:", response);
          if (response.data.Success) {
            setEmpty(false);
            // setNotifications(response.data.Data);
            setUserData(response.data.Data[0]);
            showSavedNotification();
            setDeleting(false);
            //Get PMF lock details
          } else {
            setDeleting(false);
            setEmpty(true);
            console.log("failed");
            setErrorMessage(response.data.Message);
            showDeletedNotification();
          }
        })
        .catch(function (response) {
          //handle error
          console.log(response);
          console.log("error");
        });
    } else {
      setValidated(false);
    }
  }
  //==============================UseEffect======================================
  // const [refresh, setRefresh] = useState(false);
  function TransferUser() {
    let passData = {
      Id: transferdata.id,
      FullName: transferdata.FullName,
      Mobile: transferdata.Mobile,
      Email: transferdata.Email,
      Gpay: transferdata.Gpay,
      UpiId: transferdata.UpiId,
    };
    axios({
      method: "post",
      url: Domain + "/Admin/TransferUser.php",
      data: JSON.stringify(passData),
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("Transfer Response:", response);
        if (response.data.success) {
          showTrasferedNotification();
        } else {
          console.log("failed");
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
        console.log("error");
      });
  }
  const [reactivateTextBox, setReactivateTextBox] = useState(false);
  const [referalForReactivate, setReferalForReactivate] = useState({
    RefId: "",
  });

  function ActivateUser() {
    let data = {
      Id: userData.id,
      ReferalId: referalForReactivate.RefId,
    };
    axios({
      method: "post",
      url: Domain + "/Admin/ReActivateUser.php",
      data: JSON.stringify(data),
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("ReActivateUser Response:", response);
        if (response.data.Success) {
          setReactivateTextBox(false);
          HandleSave();
        } else {
          console.log("failed");
          setErrorMessage(response.data.message);
          showDeletedNotification();
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
        console.log("error");
      });
  }
  function DeactivateUser() {
    let data = {
      Id: userData.id,
      ReferalId: userData.ReferalId,
    };
    axios({
      method: "post",
      url: Domain + "/Admin/DeactivateUser.php",
      data: JSON.stringify(data),
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("Deactivate Response:", response);
        if (response.data.Success) {
          HandleSave();
        } else {
          console.log("failed");
          setErrorMessage(response.data.message);
          showDeletedNotification();
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
        console.log("error");
      });
  }
  const [openPasswordReset, SetOpenPasswordReset] = useState(false);

  function ResetPassword() {
    let passwordResetData = {
      Id: userData.id,
      NewPassword: userData.id,
    };

    axios({
      method: "post",
      url: Domain + "/Admin/ResetPassword.php",
      data: JSON.stringify(passwordResetData),
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("ResetPassword Response:", response);
        if (response.data.Success) {
          SetOpenPasswordReset(false);
          showPwdResetNotification();
        } else {
          alert(response.data.message);
          console.log("failed");
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
        console.log("error");
      });
  }
  // console.log(openPasswordReset);
  const [openTrasfer, SetOpenTransfer] = useState(false);
  const [transferdata, setTransferdata] = useState({
    Id: "",
    FullName: "",
    Mobile: "",
    Email: "",
    Gpay: "",
    UpiId: "",
  });
  function HandleView() {
    if (openTrasfer) {
      setTransferdata([]);
      SetOpenTransfer(false);
    } else {
      setTransferdata(userData);
      SetOpenTransfer(true);
    }
  }
  const [BelowUsers, setBelowUsers] = useState([]);
  const [BelowUsers_club2, setBelowUsersClub2] = useState([]);
  const [list, setList] = useState([]);
  const [item, setItem] = useState([]);
  const [Mode, setMode] = useState("");
  // const [unlocked, setUnlocked] = useState(false);
  const [modal2, setModal2] = React.useState(false);

  function ShowUsers(list, item, mode) {
    setList(list);
    setMode(mode);
    setItem(item);
    // console.log("List:::::", list);
    setModal2(true);
  }
  useEffect(() => {
    let formData = new FormData();
    formData.append("Id", userData.id);
    axios({
      method: "post",
      url: Domain + "/Admin/GetUsersBelow.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("Below users:::", response);
        if (response.data.success) {
          setBelowUsers(response.data.data);
        } else {
          setBelowUsers([]);

          console.log("failed");
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
        console.log("error");
      });
  }, [userData.id]);
  useEffect(() => {
    let formData = new FormData();
    formData.append("Id", userData.id);
    axios({
      method: "post",
      url: Domain + "/Admin/GetUsersBelowClub2.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("Below users Club2:::", response);
        if (response.data.success) {
          setBelowUsersClub2(response.data.data);
        } else {
          setBelowUsersClub2([]);
          console.log("failed");
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
        console.log("error");
      });
  }, [userData.id]);

  const [PmfDetailsClub1, SetPmfDetailsClub1] = useState([]);
  const [PmfDetailsClub2, SetPmfDetailsClub2] = useState([]);
  useEffect(() => {
    let formData = new FormData();
    formData.append("Id", userData.id);
    formData.append("Club", "Club1");
    axios({
      method: "post",
      url: Domain + "/Admin/GetPMFdetailsByUserId.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("PMF details by id:", response);
        if (response.data.Success) {
          SetPmfDetailsClub1(response.data);
        } else {
          console.log("failed");
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
        console.log("error");
      });
  }, [userData.id]);
  useEffect(() => {
    let formData = new FormData();
    formData.append("Id", userData.id);
    formData.append("Club", "Club2");
    axios({
      method: "post",
      url: Domain + "/Admin/GetPMFdetailsByUserId.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("PMF details by id:", response);
        if (response.data.Success) {
          SetPmfDetailsClub2(response.data);
        } else {
          console.log("failed");
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
        console.log("error");
      });
  }, [userData.id]);
  console.log("PmfDetails:", PmfDetailsClub1, PmfDetailsClub2);
  return (
    <>
      <Snackbar
        place="tr"
        color="success"
        icon={AddAlert}
        message="Password Reset Successful!"
        open={pwdReset}
        closeNotification={() => setPWDReset(false)}
        close
      />
      <Snackbar
        place="tr"
        color="success"
        icon={AddAlert}
        message="ID Transfer Successful!"
        open={transfered}
        closeNotification={() => setTransfered(false)}
        close
      />
      <Snackbar
        place="tr"
        color="success"
        icon={AddAlert}
        message="User Found!"
        open={saved}
        closeNotification={() => setSaved(false)}
        close
      />
      <Snackbar
        place="tr"
        color="danger"
        icon={AddAlert}
        message={errorMessage}
        open={deleted}
        closeNotification={() => setDeleted(false)}
        close
      />

      <Modal
        isOpen={openPasswordReset}
        toggle={() => SetOpenPasswordReset(false)}
      >
        <>
          <button
            className="close"
            type="button"
            onClick={() => SetOpenPasswordReset(false)}
          >
            <i className="now-ui-icons ui-1_simple-remove"></i>
          </button>
          <h4 className="title title-up"> Reset Password</h4>
          <Card>
            <br />
            <b>Are you sure to reset password for this user?</b>

            <br />
            <br />
          </Card>

          <Button color="default" onClick={() => SetOpenPasswordReset(false)}>
            Cansel
          </Button>

          <Button color="danger" onClick={ResetPassword}>
            Reset
          </Button>
        </>
      </Modal>

      <Dialog
        fullScreen
        open={openTrasfer}
        onClose={HandleView}
        TransitionComponent={Transition}
      >
        <AppBar color="info" sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={HandleView}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <br />
        <Card>
          <br />
          <GridContainer>
            <GridItem md={12} sm={12} lg={12}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    onChange={(e) => HandleTransferData(e)}
                    value={transferdata.FullName}
                    labelText="Name"
                    id="FullName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    onChange={(e) => HandleTransferData(e)}
                    value={transferdata.Mobile}
                    labelText="Mobile"
                    id="Mobile"
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    onChange={(e) => HandleTransferData(e)}
                    value={transferdata.Email}
                    labelText="Email id"
                    id="Email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    onChange={(e) => HandleTransferData(e)}
                    value={transferdata.UpiId}
                    labelText="Upi id"
                    id="UpiId"
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    onChange={(e) => HandleTransferData(e)}
                    value={transferdata.Gpay}
                    labelText="Google pay"
                    id="Gpay"
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer className={classes.pullRight}>
                <Button round onClick={HandleView}>
                  Go back
                </Button>
                <Button color="info" round onClick={TransferUser}>
                  Save
                </Button>
              </GridContainer>
            </GridItem>
          </GridContainer>
          <br />
        </Card>
      </Dialog>

      <LoadingOverlay active={deleting} spinner text="searching Please Wait..">
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <form>
                <CardHeader color="info">
                  <h4 className={classes.cardTitleWhite}>Search User</h4>
                  <p className={classes.cardCategoryWhite}>
                    Enter any of the below detail to search a user
                  </p>
                </CardHeader>

                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        onChange={(e) => HandleData(e)}
                        value={data.Id}
                        labelText="Consumer ID"
                        id="Id"
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        onChange={(e) => HandleData(e)}
                        value={data.Mobile}
                        labelText="Mobile"
                        id="Mobile"
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        onChange={(e) => HandleData(e)}
                        value={data.Email}
                        labelText="Email id"
                        id="Email"
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={5} md={4}>
                      {validated ? (
                        <></>
                      ) : (
                        <Danger>
                          Please enter any of the details to search user
                        </Danger>
                      )}
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter>
                  <Button onClick={HandleClear} color="defualt">
                    Clear
                  </Button>
                  <Button onClick={HandleSave} color="info">
                    Search
                  </Button>
                </CardFooter>
              </form>
            </Card>
          </GridItem>
        </GridContainer>
      </LoadingOverlay>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info">
              <h4 className={classes.cardTitleWhite}>
                <b>User Details below</b>
              </h4>
            </CardHeader>
            <CardBody>
              {empty ? (
                <p>No user Found</p>
              ) : (
                // <Table
                //   tableHeaderColor="info"
                //   tableHead={[
                //     "",
                //     "ID",
                //     "Course name",
                //     "Semester Number",
                //     "Subject Name",
                //     "Course Code",
                //     "Description",
                //     "Actions",
                //   ]}
                //   tableData={Subjectdata}
                //   setEdit={setEdit}
                //   setDelete={setDelete}
                //   loading={loading}
                // />
                <>
                  {userData != "" ? (
                    <>
                      {" "}
                      <Card>
                        <CardBody>
                          Id: {userData.id}
                          <br />
                          Name: {userData.FullName}{" "}
                          <Button round>{userData.Status}</Button>
                          <br />
                          Mobile: {userData.Mobile}
                          <br />
                          Email: {userData.Email}
                          <br />
                          Upi Id: {userData.UpiId}
                          <br />
                          Gpay: {userData.Gpay}
                        </CardBody>
                        <CardFooter>
                          {userData.Status === "Active" ? (
                            <>
                              <Button color="danger" onClick={DeactivateUser}>
                                Deactivate
                              </Button>
                            </>
                          ) : (
                            <>
                              {reactivateTextBox ? (
                                <></>
                              ) : (
                                <>
                                  <Button
                                    onClick={() => setReactivateTextBox(true)}
                                  >
                                    Activate
                                  </Button>
                                </>
                              )}
                            </>
                          )}
                          {reactivateTextBox ? (
                            <>
                              <CustomInput
                                onChange={(e) => HandleReactivateData(e)}
                                value={referalForReactivate.RefId}
                                labelText="Enter the Referal Id"
                                id="RefId"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                              <Button color="success" onClick={ActivateUser}>
                                Activate
                              </Button>{" "}
                            </>
                          ) : (
                            <></>
                          )}
                          <Button color="info" onClick={HandleView}>
                            Transfer
                          </Button>
                          <Button onClick={ResetPassword}>
                            Password Reset
                          </Button>
                        </CardFooter>
                      </Card>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </CardBody>
          </Card>
          {userData != "" && !empty && userData.Status != "Deactivated" && (
            <>
              <Card>
                <CardHeader color="info">
                  <h4 className={classes.cardTitleWhite}>
                    <b>Club-1 Details</b>
                  </h4>
                  Total Amount: {userData.Club1_total}
                  <br />
                  Total PC Paid: {PmfDetailsClub1.TotalCount}
                </CardHeader>
                <CardBody>
                  <h3>G-Box</h3>
                  <GridContainer>
                    <GridItem xs={2} md={2} lg={2}>
                      <b>Sl.No</b>
                    </GridItem>
                    <GridItem xs={6} md={4} lg={4}>
                      <b>ID</b>
                    </GridItem>
                    <GridItem xs={6} md={4} lg={4}>
                      <b>Name</b>
                    </GridItem>

                    <GridItem xs={2} md={2} lg={2}>
                      <b>Status</b>
                    </GridItem>
                  </GridContainer>
                  <hr />
                  {givehelpusers.map((item, key) => (
                    <GridContainer key={key}>
                      <GridItem xs="2" md="2" lg="2">
                        <b>{key + 1}</b>
                      </GridItem>
                      <GridItem xs="6" md="4" lg="4">
                        <b>{item.PayeeId}</b>
                      </GridItem>
                      <GridItem xs="6" md="4" lg="4">
                        <b>{item.PayeeName}</b>
                      </GridItem>

                      <GridItem xs="2" md="2" lg="2">
                        <Button color="success" className="mr-1">
                          Paid
                        </Button>
                      </GridItem>
                    </GridContainer>
                  ))}
                  <hr />

                  <h3>R-Box</h3>
                  <GridContainer>
                    <GridItem xs="2" md="2" lg="2">
                      <b>Stage</b>
                    </GridItem>
                    <GridItem xs="3" md="4" lg="4">
                      <b>Total per Stage</b>
                    </GridItem>
                    <GridItem xs="3" md="3" lg="3">
                      <b>Filled</b>
                    </GridItem>
                    <GridItem xs="2" md="2" lg="2">
                      <b> Paid</b>
                    </GridItem>
                  </GridContainer>
                  <hr />
                  {BelowUsers.map((item, key) => (
                    <GridContainer key={key}>
                      <GridItem xs="2" md="2" lg="2">
                        {item.Id}
                      </GridItem>
                      <GridItem xs="3" md="4" lg="4">
                        <Button
                          style={{
                            background: "white",
                            color: "#2ca8ff",
                            minWidth: 59,
                          }}
                          className="mr-1"
                        >
                          <b>{item.Total}</b>
                        </Button>
                      </GridItem>
                      <GridItem xs="3" md="3" lg="3">
                        <Button
                          style={{
                            background: "#4269ff",
                            minWidth: 59,
                          }}
                          onClick={() => ShowUsers(item.Filled, item, "Filled")}
                          className="mr-1"
                        >
                          {item.Filled != null ? item.Filled.length : 0}
                        </Button>
                      </GridItem>
                      <GridItem xs="2" md="2" lg="2">
                        <Button
                          style={{
                            minWidth: 59,
                          }}
                          color="success"
                          onClick={() => ShowUsers(item.Paid, item, "Paid")}
                          className="mr-1"
                        >
                          {item.Paid != null ? item.Paid.length : 0}
                        </Button>
                      </GridItem>
                    </GridContainer>
                  ))}
                </CardBody>
              </Card>

              {/* Club2 Details */}
              <Card>
                <CardHeader color="info">
                  <h4 className={classes.cardTitleWhite}>
                    <b>Club-2 Details</b>
                  </h4>
                  Total Amount: {userData.Club2_total}
                  <br />
                  Total PC Paid: {PmfDetailsClub2.TotalCount}
                </CardHeader>
                <CardBody>
                  <h3>G-Box</h3>
                  <GridContainer>
                    <GridItem xs={2} md={2} lg={2}>
                      <b>Sl.No</b>
                    </GridItem>
                    <GridItem xs={6} md={4} lg={4}>
                      <b>ID</b>
                    </GridItem>
                    <GridItem xs={6} md={4} lg={4}>
                      <b>Name</b>
                    </GridItem>

                    <GridItem xs={2} md={2} lg={2}>
                      <b>Status</b>
                    </GridItem>
                  </GridContainer>
                  <hr />
                  {givehelpusers_club2.map((item, key) => (
                    <GridContainer key={key}>
                      <GridItem xs="2" md="2" lg="2">
                        <b>{key + 1}</b>
                      </GridItem>
                      <GridItem xs="6" md="4" lg="4">
                        <b>{item.PayeeId}</b>
                      </GridItem>
                      <GridItem xs="6" md="4" lg="4">
                        <b>{item.PayeeName}</b>
                      </GridItem>

                      <GridItem xs="2" md="2" lg="2">
                        <Button color="success" className="mr-1">
                          Paid
                        </Button>
                      </GridItem>
                    </GridContainer>
                  ))}
                  <hr />

                  <h3>R-Box</h3>
                  <GridContainer>
                    <GridItem xs="2" md="2" lg="2">
                      <b>Stage</b>
                    </GridItem>
                    <GridItem xs="3" md="4" lg="4">
                      <b>Total per Stage</b>
                    </GridItem>
                    <GridItem xs="3" md="3" lg="3">
                      <b>Filled</b>
                    </GridItem>
                    <GridItem xs="2" md="2" lg="2">
                      <b> Paid</b>
                    </GridItem>
                  </GridContainer>
                  <hr />
                  {BelowUsers_club2.map((item, key) => (
                    <GridContainer key={key}>
                      <GridItem xs="2" md="2" lg="2">
                        {item.Id}
                      </GridItem>
                      <GridItem xs="3" md="4" lg="4">
                        <Button
                          style={{
                            background: "white",
                            color: "#2ca8ff",
                            minWidth: 59,
                          }}
                          className="mr-1"
                        >
                          <b>{item.Total}</b>
                        </Button>
                      </GridItem>
                      <GridItem xs="3" md="3" lg="3">
                        <Button
                          style={{
                            background: "#4269ff",
                            minWidth: 59,
                          }}
                          onClick={() => ShowUsers(item.Filled, item, "Filled")}
                          className="mr-1"
                        >
                          {item.Filled != null ? item.Filled.length : 0}
                        </Button>
                      </GridItem>
                      <GridItem xs="2" md="2" lg="2">
                        <Button
                          style={{
                            minWidth: 59,
                          }}
                          color="success"
                          onClick={() => ShowUsers(item.Paid, item, "Paid")}
                          className="mr-1"
                        >
                          {item.Paid != null ? item.Paid.length : 0}
                        </Button>
                      </GridItem>
                    </GridContainer>
                  ))}
                </CardBody>
              </Card>
            </>
          )}
        </GridItem>
      </GridContainer>
      <Dialog
        fullScreen
        open={modal2}
        onClose={() => setModal2(false)}
        TransitionComponent={Transition}
      >
        <AppBar color="info" sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => setModal2(false)}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <br />
        <br />
        <br />

        <div className="modal-header justify-content-center"></div>
        <CardBody>
          <h4
            style={{ color: "rgba(46, 116, 186)" }}
            className="title title-up"
          >
            <b> {Mode} People </b> ( Stage -{item.Id} )
          </h4>
          <GridContainer>
            {list ? (
              list.map((user, key) => (
                <GridItem key={key} xs="12" md="4" lg="4">
                  <Card
                    style={{
                      color: "white",
                      background:
                        "linear-gradient(to right, rgba(104, 198, 222),rgb(36, 188, 237), rgba(46, 116, 186))",
                    }}
                  >
                    <CardBody>
                      {" "}
                      <h3>
                        <b>
                          {user.FullName} <br />
                        </b>
                      </h3>
                      <h4 style={{ marginTop: 0, marginBottom: 0 }}>
                        ID:{user.id}
                      </h4>
                      Mobile: {user.Mobile}
                      <br />
                      Gpay: {user.Gpay}
                      <br />
                    </CardBody>
                  </Card>
                </GridItem>
              ))
            ) : (
              <>Zero People in this Stage</>
            )}
          </GridContainer>
        </CardBody>
      </Dialog>
    </>
  );
}
