import PeopleIcon from "@material-ui/icons/People";
import DoctorsList from "views/DoctorsList/DoctorsList";
import DashboardPage from "views/Dashboard/Dashboard.js";
import Dashboard from "@material-ui/icons/Dashboard";

const dashboardRoutes = [
  {
    path: "/pmf-details",
    name: "PMF Details",
    icon: PeopleIcon,
    component: DoctorsList,
    layout: "/admin",
  },
  {
    path: "/recieve-help",
    name: "Recieve Help",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
  },
];

export default dashboardRoutes;
