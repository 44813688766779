import React, { useEffect, useState } from "react";
import { Domain } from "Domain";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import CardFooter from "components/Card/CardFooter.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import axios from "axios";

// import ImageUpload from "components/CustomUpload/ImageUpload.js";

// import AttachFile from "@material-ui/icons/AttachFile";
// import CustomFileInput from "components/CustomFileInput/CustomFileInput.js";
// import SingleSelect from "components/SingleSelect";
import Danger from "components/Typography/Danger";

// import { data } from "./data.json";
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardCategoryGrey: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgb(128,128,128)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
      fontWeight: "500",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardStyle: {
    padding: "10px",
    paddingRight: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export default function RaiseTicket() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  const classes = useStyles();
  const [saved, setSaved] = useState(false);
  const [deleted, setDeleted] = useState(false);
  // const [files, setFiles] = useState(null);
  const [validated, setValidated] = React.useState(true);
  // const [Developers, setDevelopers] = React.useState([]);
  const [userDetails, SetUserDetails] = React.useState([]);

  useEffect(() => {
    SetUserDetails(JSON.parse(window.localStorage.getItem("userdetails")));
  }, []);
  console.log("userDetails::", userDetails);
  //Saved Notification trigger
  const showSavedNotification = () => {
    if (!saved) {
      setSaved(true);
      setTimeout(function () {
        setSaved(false);
      }, 3000);
    }
  };
  //Deleted Notification Trigger
  const showDeletedNotification = () => {
    if (!deleted) {
      setDeleted(true);
      setTimeout(function () {
        setDeleted(false);
      }, 3000);
    }
  };

  //Form Data
  const [data, setData] = React.useState({
    UpiId: "",
    Mobile: "",
    Gpay: "",
  });
  //Function to handle Data input
  function HandleData(e) {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
    console.log(newData);
  }
  function HandleClear() {
    setData({
      UpiId: "",
      Mobile: "",
      Gpay: "",
    });
  }
  //Function for Validating fields
  // function ValidateFields() {
  //   if (data.FullName == "") {
  //     return false;
  //   } else if (DeveloperValues.Id == null) {
  //     return false;
  //   } else if (data.Subject == "") {
  //     return false;
  //   } else if (data.Description == "") {
  //     return false;
  //   } else return true;
  // }
  //function to upload
  // function UploadImage() {
  //   if (files != null) {
  //     let formData = new FormData();
  //     formData.append("Image", files[0]);
  //     // console.log(formData);
  //     axios({
  //       method: "post",
  //       url: Domain + "/upload_file.php",
  //       data: formData,
  //       config: { headers: { "Content-Type": "multipart/form-data" } },
  //     })
  //       .then(function (response) {
  //         //handle success
  //         console.log("File Upload Response:", response.data);

  //         data.Image = response.data;
  //         HandleSave();
  //       })
  //       .catch(function (response) {
  //         //handle error
  //         console.log(response);
  //         showDeletedNotification();
  //       });
  //   } else {
  //     HandleSave();
  //   }
  // }

  function HandleSave() {
    setValidated(true);
    let formData = new FormData();

    formData.append("UpiId", data.UpiId);
    formData.append("Mobile", data.Mobile);
    formData.append("Gpay", data.Gpay);
    axios({
      method: "post",
      url: Domain + "/Admin/UpdateCompanyDetails.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log(response);
        if (response.data.success) {
          HandleClear();
          console.log("saved");
          showSavedNotification();
        } else {
          console.log("failed");
          showDeletedNotification();
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
        showDeletedNotification();
      });
  }
  useEffect(() => {
    let formData = new FormData();
    formData.append("Status", "Active");
    axios({
      method: "post",
      url: Domain + "/Admin/GetCompanyDetails.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log(response);
        if (response.data.TotalCount != 0) {
          if (response.data.Success) {
            console.log("Compny details:", response.data.Data);
            setData(response.data.Data[0]);
          } else {
            console.log("failed");
          }
        } else {
          console.log(" empty");
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  }, []);

  return (
    <>
      <Snackbar
        place="bc"
        color="success"
        icon={AddAlert}
        message="Updated Successfully!"
        open={saved}
        closeNotification={() => setSaved(false)}
        close
      />
      <Snackbar
        place="bc"
        color="danger"
        icon={AddAlert}
        message="Error! Try again"
        open={deleted}
        closeNotification={() => setDeleted(false)}
        close
      />

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info">
              <h4 className={classes.cardTitleWhite}>Update Company profile</h4>
              <p className={classes.cardCategoryWhite}>
                Fill up the details and click Save
              </p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {/* <GridItem xs={12} sm={12} md={6}>
                  <SingleSelect
                    noOptionsMessage="No projects available"
                    placeholder="Select Project"
                    Options={DevelopersList}
                    setValue={setDevelopersValue}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem> */}

                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    onChange={(e) => HandleData(e)}
                    value={data.UpiId}
                    labelText="UpiId"
                    id="UpiId"
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    onChange={(e) => HandleData(e)}
                    value={data.Gpay}
                    labelText="Gpay"
                    id="Gpay"
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    onChange={(e) => HandleData(e)}
                    value={data.Mobile}
                    labelText="Mobile"
                    id="Mobile"
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  {/* <CustomFileInput
                    setFiles={setFiles}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      placeholder: "",
                    }}
                    endButton={{
                      buttonProps: {
                        round: true,
                        color: "info",
                        justIcon: true,
                      },
                      icon: <AttachFile />,
                    }}
                  /> */}
                  {validated ? (
                    <></>
                  ) : (
                    <Danger>Input all the mandatory fields</Danger>
                  )}
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button onClick={HandleClear}>Clear</Button>
              <Button onClick={HandleSave} color="info">
                Save
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}
