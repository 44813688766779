import Dashboard from "@material-ui/icons/Dashboard";
import PeopleIcon from "@material-ui/icons/People";
// import ClientsList from "views/ClientsList/ClientsList";
import DashboardPage from "views/Dashboard/Dashboard.js";
import DoctorsList from "views/DoctorsList/DoctorsList";
import Subjects from "views/Subjects/Subjects";
// import TicketStatusAdmin from "views/TicketStatusAdmin/TicketStatusAdmin";
// import Modalities from "views/Modalities/Modalities";
import RaiseTicket from "views/RaiseTicket/RaiseTicket";
import RecentUsers from "views/RecentUsers/RecentUsers";
import AutoDeactivated from "views/AutoDeactivated/AutoDeactivated";
import PCReport from "views/PCReport/PCReport";
// import Staffs from "views/Sfatts/Staffs";
// import TicketStatusAdmin from "views/TicketStatusAdmin/TicketStatusAdmin";

const dashboardRoutes = [
  {
    path: "/pmf-details",
    name: "PMF Details",
    icon: PeopleIcon,
    component: DoctorsList,
    layout: "/admin",
  },
  {
    path: "/recieve-help",
    name: "Recieve Help",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
  },

  {
    path: "/deactivated-users",
    name: "Auto Deactivated",
    icon: PeopleIcon,
    component: AutoDeactivated,
    layout: "/admin",
  },
  {
    path: "/consumer-details",
    name: "Cunsumer Details",
    icon: PeopleIcon,
    component: Subjects,
    layout: "/admin",
  },

  // {
  //   path: "/clients-list",
  //   name: "Clients List",
  //   icon: PeopleIcon,
  //   component: ClientsList,
  //   layout: "/admin",
  // },

  {
    path: "/profile",
    name: "Company Profile",
    icon: "content_paste",
    component: RaiseTicket,
    layout: "/admin",
  },
  {
    path: "/recent-registrations",
    name: "Recent Registrations",
    icon: Dashboard,
    component: RecentUsers,
    layout: "/admin",
  },
  {
    path: "/pc-report",
    name: "PC report",
    icon: Dashboard,
    component: PCReport,
    layout: "/admin",
  },
];

export default dashboardRoutes;
