import PeopleIcon from "@material-ui/icons/People";
import Subjects from "views/Subjects/Subjects";

const dashboardRoutes = [
  {
    path: "/consumer-details",
    name: "Cunsumer Details",
    icon: PeopleIcon,
    component: Subjects,
    layout: "/admin",
  },
];

export default dashboardRoutes;
