import axios from "axios";
import { Domain } from "Domain";
import React, { useEffect, useState } from "react";
import Table from "components/Table/Table1.js";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
// import CustomInput from "components/CustomInput/CustomInput";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ReactToPrint from "react-to-print";
// import * as XLSX from "xlsx";
import XlsxPopulate from "xlsx-populate";
import { saveAs } from "file-saver";

// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { Domain } from "Domain";
// import { makeStyles } from "@material-ui/core/styles";
// import ConfirmationNumberSharpIcon from "@material-ui/icons/ConfirmationNumberSharp";
// import Update from "@material-ui/icons/Update";
// import GridItem from "components/Grid/GridItem.js";
// import GridContainer from "components/Grid/GridContainer.js";
// import Card from "components/Card/Card.js";
// import CardHeader from "components/Card/CardHeader.js";
// import CardIcon from "components/Card/CardIcon.js";
// import CardFooter from "components/Card/CardFooter.js";
// import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import Button from "components/CustomButtons/Button.js";
import { Dialog, Slide, TextField } from "@material-ui/core";
import { useRef } from "react";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
// const useStyles = makeStyles(styles);

export default function PCReport() {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  let componentRef = useRef();

  const [notifications, setNotifications] = useState([]);
  const [edit, setEdit] = React.useState([]);
  const [refresh, setRefresh] = useState(false);

  const Invoice = notifications.map((d, key) => ({
    Id: key + 1,
    InvoiceNumber: d.Id,
    Name: d.PayerName,
    State: d.State != "" ? d.State : "Karnataka",
    Date: d.Date.split(" ")[0],
    Amount: d.pfmAmount + "/-",
    Gst: (d.pfmAmount * 18) / 100 + "/-",
    TotalAmount: d.Amount,
  }));
  console.log("edit::", edit);
  useEffect(() => {
    let formData = new FormData();
    axios({
      method: "post",
      url: Domain + "/Admin/GetPMFdetails.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("PMF details:", response);
        if (response.data.Success) {
          setNotifications(response.data.Data);
          //Get PMF lock details
        } else {
          console.log("failed");
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
        console.log("error");
      });
  }, [refresh]);

  const [Open, SetOpen] = useState(false);
  useEffect(() => {
    if (edit != "") {
      SetOpen(true);
    }
  }, [edit]);
  function ConfirmPayment() {
    let data = {
      Id: edit,
    };
    axios({
      method: "post",
      url: Domain + "/Admin/ConfrimPMFentry.php",
      data: JSON.stringify(data),
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("Confirmed:", response);
        if (response.data.Success) {
          if (refresh) setRefresh(false);
          else setRefresh(true);
          //Get PMF lock details
          HandleOpen();
        } else {
          console.log("failed");
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
        console.log("error");
      });
  }
  const [Generated, setGenerated] = useState(false);
  function HandleDateSearch() {
    let formData = new FormData();
    formData.append("StartDate", startDate);
    formData.append("EndDate", endDate);
    axios({
      method: "post",
      url: Domain + "/Admin/GetPCReportByDate.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("PMF details by Date:", response);
        if (response.data.Success) {
          setNotifications(response.data.Data);
          // setSumOfpmf(response.data.TotalSum);
          setGenerated(true);
        } else {
          console.log("failed");
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
        console.log("error");
      });
  }
  function HandleOpen() {
    if (Open) {
      SetOpen(false);
      setEdit("");
    } else SetOpen(true);
  }
  function getSheetData(data, header) {
    var fields = Object.keys(data[0]);
    var sheetData = data.map(function (row) {
      return fields.map(function (fieldName) {
        return row[fieldName] ? row[fieldName] : "";
      });
    });
    sheetData.unshift(header);
    return sheetData;
  }
  async function saveAsExcel() {
    if (Invoice) {
      let header = [
        "Sl.No",
        "Transaction Id",
        "Name",
        "State",
        "Date",
        "Amount",
        "GST",
        "Total",
      ];

      XlsxPopulate.fromBlankAsync().then(async (workbook) => {
        const sheet1 = workbook.sheet(0);
        const sheetData = getSheetData(Invoice, header);
        const totalColumns = sheetData[0].length;

        sheet1.cell("A1").value(sheetData);
        sheet1.column("A").width(10);
        sheet1.column("B").width(25);
        sheet1.column("C").width(25);
        sheet1.column("D").width(25);
        sheet1.column("E").width(25);
        sheet1.column("F").width(25);
        sheet1.column("G").width(25);
        const range = sheet1.usedRange();
        const endColumn = String.fromCharCode(64 + totalColumns);
        sheet1.row(1).style("bold", true);
        sheet1.range("A1:" + endColumn + "1").style("fill", "d3d3d3");
        range.style("border", false);
        return workbook.outputAsync().then((res) => {
          saveAs(res, "_invoice.xlsx");
        });
      });
    }
  }
  return (
    <>
      <Dialog open={Open} onClose={HandleOpen} TransitionComponent={Transition}>
        <>
          <Card>
            <b> Are you sure to Confirm? </b>
            <br />
            <Button color="default" onClick={HandleOpen}>
              Cansel
            </Button>

            <Button color="success" onClick={ConfirmPayment}>
              Confirm
            </Button>
          </Card>
        </>
      </Dialog>
      <br />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info">PC Report</CardHeader>
            <br />
            <GridContainer>
              {/* <GridItem xs={12} sm={6} md={6}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    Search By Id
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      onChange={(e) => HandleData(e)}
                      value={data.Id}
                      labelText="Enter Id"
                      id="Id"
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                
                </GridContainer>
              </GridItem> */}
              {/* <GridItem xs={12} sm={12} md={12}>
                <Button color="info" onClick={HandleSearch}>
                  Search
                </Button>
              </GridItem> */}
              <GridItem xs={12} sm={12} md={12}>
                <GridContainer>
                  <GridItem xs={12} sm={2} md={2}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="Start Date"
                        value={startDate}
                        onChange={(newValue) => {
                          setStartDate(newValue.toLocaleDateString("en-CA"));
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </GridItem>
                  <GridItem xs={12} sm={2} md={2}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="End Date"
                        value={endDate}
                        onChange={(newValue) => {
                          setEndDate(newValue.toLocaleDateString("en-CA"));
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </GridItem>
                  <GridItem xs={12} sm={2} md={2}>
                    <Button color="info" onClick={HandleDateSearch}>
                      Get Report
                    </Button>
                    <Button onClick={saveAsExcel} color="success">
                      Download Excel
                    </Button>
                  </GridItem>
                  <GridItem xs={12} sm={2} md={2}>
                    {Generated && (
                      <ReactToPrint
                        trigger={() => (
                          <Button color="danger">Download PDF</Button>
                        )}
                        content={() => componentRef}
                      />
                    )}
                  </GridItem>
                </GridContainer>
                <br />
                {/* Total Count : <b>{Notifications.length}</b> */}
                {/* <br /> Total PMF Amount: <b>{SumOfpmf}</b> */}
              </GridItem>
            </GridContainer>

            {/* <Table
              tableHeaderColor="info"
              tableHead={[
                "Sl.No",
                "Consumer Id",
                "Name",
                "Amount",
                "Club",
                "TotalAmount",
                "Date",
                "Status",
                "Actions",
              ]}
              tableData={Notifications}
              setEdit={setEdit}
              loading={false}
            /> */}
          </Card>
        </GridItem>
      </GridContainer>
      <div style={{ display: Generated ? "" : "none" }}>
        <div ref={(el) => (componentRef = el)}>
          {/* <img src={LogoHeader} alt="" width="100%" /> */}
          <Card>
            <CardHeader>
              <center>
                <h4>
                  <b>Platform Charge</b>
                </h4>
                <h3>Ealit Online Marketing pvt. Ltd.</h3>
                <h4> GST IN 29AAHCE33312K1ZL </h4>
                <h5>
                  From {startDate} to {endDate}
                </h5>
              </center>
            </CardHeader>
            <Table
              tableHeaderColor="info"
              tableHead={[
                "Sl.No",
                "Transaction Id",
                "Name",
                "State",
                "Date",
                "Amount",
                "GST ( 18% )",
                "Total",
              ]}
              noAction={true}
              tableData={Invoice}
              setEdit={setEdit}
              loading={false}
            />
          </Card>
        </div>
      </div>
    </>
    // <div>
    //   <GridContainer>
    //     <GridItem xs={12} sm={6} md={3}>
    //       <Card>
    //         <CardHeader color="warning" stats icon>
    //           <CardIcon color="warning">
    //             <ConfirmationNumberSharpIcon />
    //           </CardIcon>
    //           <p className={classes.cardCategory}>
    //             <b>Recently Raised Tickets</b>
    //           </p>
    //           <h3 className={classes.cardTitle}>
    //             <b>{Raised}</b>
    //           </h3>
    //         </CardHeader>
    //         <CardFooter stats>
    //           <div className={classes.stats}>
    //             <Update />
    //             Just Updated
    //           </div>
    //         </CardFooter>
    //       </Card>
    //     </GridItem>
    //     <GridItem xs={12} sm={6} md={3}>
    //       <Card>
    //         <CardHeader color="primary" stats icon>
    //           <CardIcon color="primary">
    //             <ConfirmationNumberSharpIcon />
    //           </CardIcon>
    //           <p className={classes.cardCategory}>
    //             <b>Assigned Tickets Count</b>
    //           </p>
    //           <h3 className={classes.cardTitle}>
    //             <b>{Assigned}</b>
    //           </h3>
    //         </CardHeader>
    //         <CardFooter stats>
    //           <div className={classes.stats}>
    //             <Update />
    //             Just Updated
    //           </div>
    //         </CardFooter>
    //       </Card>
    //     </GridItem>
    //     <GridItem xs={12} sm={6} md={3}>
    //       <Card>
    //         <CardHeader color="info" stats icon>
    //           <CardIcon color="info">
    //             <ConfirmationNumberSharpIcon />
    //           </CardIcon>
    //           <p className={classes.cardCategory}>
    //             <b>Working Tickets Count.. </b>
    //           </p>
    //           <h3 className={classes.cardTitle}>
    //             <b>{Working}</b>
    //           </h3>
    //         </CardHeader>
    //         <CardFooter stats>
    //           <div className={classes.stats}>
    //             <Update />
    //             Just Updated
    //           </div>
    //         </CardFooter>
    //       </Card>
    //     </GridItem>
    //     <GridItem xs={12} sm={6} md={3}>
    //       <Card>
    //         <CardHeader color="success" stats icon>
    //           <CardIcon color="success">
    //             <ConfirmationNumberSharpIcon />
    //           </CardIcon>
    //           <p className={classes.cardCategory}>
    //             <b>Completed Ticket Count</b>
    //           </p>
    //           <h3 className={classes.cardTitle}>
    //             <b>{Completed}</b>
    //           </h3>
    //         </CardHeader>
    //         <CardFooter stats>
    //           <div className={classes.stats}>
    //             <Update />
    //             Just Updated
    //           </div>
    //         </CardFooter>
    //       </Card>
    //     </GridItem>
    //     <GridItem xs={12} sm={6} md={3}>
    //       <Card>
    //         <CardHeader color="danger" stats icon>
    //           <CardIcon color="danger">
    //             <ConfirmationNumberSharpIcon />
    //           </CardIcon>
    //           <p className={classes.cardCategory}>
    //             <b>Pending Ticket Count</b>
    //           </p>
    //           <h3 className={classes.cardTitle}>
    //             <b>{Pending}</b>
    //           </h3>
    //         </CardHeader>
    //         <CardFooter stats>
    //           <div className={classes.stats}>
    //             <Update />
    //             Just Updated
    //           </div>
    //         </CardFooter>
    //       </Card>
    //     </GridItem>
    //   </GridContainer>
    // </div>
  );
}
